import React from 'react';

import { useParams } from 'react-router-dom';
import { Stack, CircularProgress } from '@mui/material';

interface UrlParams { t: string; }
  
interface FwdScreenProps { }
export const FwdScreen: React.FC<FwdScreenProps> = (props) => {

    let { t } = useParams();

    const handleFirst = () => {
        let tp = t?.substring(0, 1);
        let tkn = t?.substring(1);

        if (tp === '1') { window.open("https://eregcr.com/eRegCRAdmin/auth/api?act=eRegProyek&tkn=" + tkn, "_self"); } 
        else if (tp === '3') { window.open("https://eregcr.com/eRegCRAdmin/auth/api?act=uploadKPR&tkn=" + tkn, "_self"); } 
        else { window.open("https://eregcr.com/eRegCRAdmin/auth/api?act=statusMemberId&activation=1&tkn=" + tkn, "_self"); }
    }

    React.useEffect(() => { handleFirst(); }, []);
    

    return (
        <Stack sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            paddingTop: '25%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffffff'
        }}> <CircularProgress /> </Stack>
    );
};