import React from 'react';

// External Import
import { Alert, Slide, SlideProps, Snackbar } from '@mui/material';


export type StatusProps = undefined|'success' | 'error' | 'warning';
export type SnackbarProps = { open: boolean, status: StatusProps, message: string }
export type SnackbarComponentProps = { snackbar: SnackbarProps, handleCloseSnackbar: ()=>void }

export const SnackbarComponent: React.FC<SnackbarComponentProps> = ({snackbar, handleCloseSnackbar}) => {

    return (
        <Snackbar
            open={snackbar.open}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            TransitionComponent={(props: SlideProps) => (<Slide {...props} direction="left" />)}
        >
            <Alert severity={snackbar.status} onClose={handleCloseSnackbar}>{snackbar.message}</Alert>
        </Snackbar>
    );
};