import React from 'react';

// External Import
import { Helmet } from 'react-helmet';

interface HelmetComponentProps {}
export const HelmetComponent: React.FC<HelmetComponentProps> = () => {
    return (
        <Helmet>
            <title>{process.env.REACT_APP_PROJECT_NAME}</title>
            <meta name="description" content="This is a proyek Ciputra Group - SH1" />
        </Helmet>
    );
};