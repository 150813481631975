import React from 'react';

// Internal Import
import { HelmetComponent } from '../../components/helmet';
import { AppbarComponent } from '../../components/appbar';
import { ModalOtpComponent } from '../../components/modal/otp';
import { ResendOtpReq, VerifyOtpReq } from '../../utils/structure/input';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../components/snackbar';
import { DefaultRes, StatusMemberDataRes, StatusMemberRes } from '../../utils/structure/output';

// Extenal Import
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';

interface StatusMemberScreenProps { }
export const StatusMemberScreen: React.FC<StatusMemberScreenProps> = (props) => {

    let navigate = useNavigate();
    const { id } = useParams();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isLoadingOtp, setIsLoadingOtp] = React.useState<boolean>(false);
    const [isLoadingResend, setIsLoadingResend] = React.useState<boolean>(false);
    const [statusData, setStatusData] = React.useState<StatusMemberDataRes | undefined>(undefined);

    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string, time: number) => {
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        setTimeout(() => { handleCloseSnackbar(); }, time);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };

    // OTP
    const [otp, setOtp] = React.useState<string>("");
    const [modalOtpReg, setModalOtpReg] = React.useState<boolean>(false);
    const handleOpenModalReg = () => { setModalOtpReg(true); }
    const handleCloseModalReg = () => { 
        setOtp("");
        setModalOtpReg(false);
    }
    const handleResendOtp = async() => {
        if (isLoadingResend) { return }
        setIsLoadingResend(true);
        let requestData: ResendOtpReq = {
            id: id!,
            office: statusData?.office!,
            code_project: process.env.REACT_APP_PROJECT_CODE!
        }
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_RESEND_OTP!;
            const response: AxiosResponse = await axios.post(url, requestData, { headers: {'Accept': 'application/json', 'Content-Type': 'application/json'} });
            let resendOtpResponse: DefaultRes = response.data;

            handleOpenSnackbar('success', resendOtpResponse.message, 3000);
            setTimeout(() => {
                setIsLoadingResend(false);
                handleOpenModalReg();
            }, 3000);
        } catch (error) {
            setIsLoadingResend(false);
            const axiosError = error as AxiosError;
            let errResponse: DefaultRes | any = axiosError.response?.data;
            handleOpenSnackbar('error', errResponse?.message, 5000);
        }
    }

    const handleSubmitOtp = async() => {
        setIsLoadingOtp(true);
        let requestData: VerifyOtpReq = {
            id: id!,
            otp: otp,
            ereg_project: process.env.REACT_APP_PROJECT_ID_EREG!,
            code_project: process.env.REACT_APP_PROJECT_CODE!,
            citralink_project: process.env.REACT_APP_PROJECT_ID_CTRLNK!,
        }
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_VERIFY_OTP!;
            const response: AxiosResponse = await axios.post(url, requestData, { headers: {'Accept': 'application/json', 'Content-Type': 'application/json'} });
            let otpResponse: DefaultRes = response.data;

            setIsLoadingOtp(false);
            if (otpResponse.status == 200) {
                handleCloseModalReg();
                handleOpenSnackbar('success', otpResponse.message, 3000);
                setTimeout(()=> { navigate(-1); }, 3000);
            } else {
                handleOpenSnackbar('error', otpResponse.message, 3000);
            }
        } catch (error) {
            setIsLoadingOtp(false);
            const axiosError = error as AxiosError;
            let errResponse: DefaultRes | any = axiosError.response?.data;
            handleOpenSnackbar('error', errResponse?.message, 5000);
            handleCloseModalReg();
        }
    }

    const getData = async () => {
        setIsLoading(true);
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_STATUS_MEMBER!+'?id='+id;
            const response: AxiosResponse = await axios.get(url, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
            let dtStatus: StatusMemberRes = response.data;

            if (dtStatus.status === 200) {
                setStatusData(dtStatus.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setStatusData(undefined);
                handleOpenSnackbar('warning', "Member tidak ditemukan", 5000);
            }
        } catch (error) {
            setIsLoading(false);
            setStatusData(undefined);
            handleOpenSnackbar('warning', "Member tidak ditemukan", 3000);
        }
    }

    React.useEffect(() => { getData(); }, []);

    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            minHeight: '100vh',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: '#F1F1F7'
        }}>
            <HelmetComponent />
            <AppbarComponent />
            <SnackbarComponent snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />

            <Box sx={{
                mt: '3em',
                mb: '1em',
                height: '100%',
                width: { xs: '90%', sm: '85%', md: '80%', lg: '65%', xl: '70%' }
            }}>
                <Typography sx={{
                    mb: '0.2em',
                    fontWeight: '500',
                    fontFamily: 'Open Sans',
                    fontSize: { xs: '1.2em', sm: '1.3em', md: '1.5em', lg: '1.5em', xl: '1.7em' }
                }}>Status Member</Typography>
            </Box>

            {isLoading && (
                <Stack sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <CircularProgress />
                </Stack>
            )}

            {!isLoading && statusData != undefined && (
                <Box sx={{
                    mb: '1.5em',
                    borderRadius: '0.8em',
                    backgroundColor: '#ffffff',
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
                    width: { xs: '90%', sm: '80%', md: '70%', lg: '70%', xl: '60%' }
                }}>
                    <Box sx={{
                        mx: 4,
                        flex: 1,
                        mb: '1em',
                        mt: '1em',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Typography sx={{
                            fontFamily: 'Open Sans',
                            fontSize: { xs: '0.55em', sm: '0.6em', md: '0.7em', lg: '0.8em', xl: '0.8em' },
                        }}>
                            Untuk melakukan aktivasi member <b>{statusData.fullname}</b> kode OTP akan dikirim ke Whatsapp number <b>{statusData.phone}</b> anda. Untuk melakukan aktivasi member Anda silakan <span onClick={handleResendOtp} style={{
                                color: '#008060',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}>{isLoadingResend?"Loading...":"Klik Disini"}</span>
                        </Typography>
                    </Box>
                </Box>
            )}
            <ModalOtpComponent
                otp={otp}
                setOtp={setOtp}
                open={modalOtpReg}
                isLoading={isLoadingOtp}
                handleResend={handleResendOtp}
                handleSubmit={handleSubmitOtp}
                handleClose={handleCloseModalReg}
                isLoadingResend={isLoadingResend}
                phone={statusData?.phone!}
            />
        </Stack>
    );
};