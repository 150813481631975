import React from 'react';

// External Import
import OTPInput from 'react-otp-input';

// MUI Import
import { Box, Modal, Button } from '@mui/material';


// Image Import

export type ModalOtpComponentProps = {
    otp: string
    open: boolean
    phone: string
    isLoading: boolean
    handleClose: ()=>void
    handleSubmit: ()=>void
    handleResend: ()=>void
    isLoadingResend: boolean,
    setOtp: (value: string)=>void
}
export const ModalOtpComponent: React.FC<ModalOtpComponentProps> = ({otp, open, phone, setOtp, isLoading, handleClose, handleSubmit, handleResend, isLoadingResend }) => {

    return (

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                px: '1em',
                pt: '1em',
                pb: '1.4em',
                top: '50%',
                left: '50%',
                boxShadow: 24,
                border: 'none',
                outline: 'none',
                position: 'absolute',
                borderRadius: '0.25em',
                bgcolor: 'background.paper',
                transform: 'translate(-50%, -50%)',
                width: { xs: '80%', sm: '60%', md: '60%', lg: '40%', xl: '30%' },
            }}>
                <table style={{width: '100%'}}>
                    <tr>
                        <td colSpan={3} style={{
                            fontSize: '1.5em',
                            fontWeight: 'bold',
                            fontFamily: 'Open Sans',
                        }}>OTP Verifikasi</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{
                            fontSize: '1em',
                            paddingBottom: '1.75em',
                            fontFamily: 'Open Sans',
                        }}>
                            Masukkan kode OTP yang dikirim ke Whatsapp number <b>{phone}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{
                            fontSize: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            
                            paddingBottom: '1.75em',
                            fontFamily: 'Open Sans',
                        }}>
                            <OTPInput
                                value={otp}
                                numInputs={6}
                                onChange={setOtp}
                                renderSeparator={<span>&nbsp;-&nbsp;</span>}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={{ fontSize: '1.2em', padding: '0.2em'}}
                            />
                        </td>
                    </tr>
                    
                    <tr>
                        <td colSpan={3} style={{
                            fontSize: '0.8em',
                            paddingBottom: '0.75em',
                            fontFamily: 'Open Sans',
                        }}>
                            Tidak menerima kode OTP? {isLoadingResend?(
                                <span style={{ fontWeight: 'bold', color: 'gray' }}>Loading...</span>
                            ):(
                                <span style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }} onClick={handleResend}>Kirim ulang kode</span>
                            )}
                        </td>
                    </tr>
                </table>
                <Box>
                    <Button
                        fullWidth
                        size='medium'
                        type='button'
                        color='warning'
                        variant="contained"
                        disabled={isLoading}
                        onClick={handleSubmit}
                    >{isLoading?"Loading...":"Verify"}</Button>
                </Box>
            </Box>
        </Modal>
    );
};