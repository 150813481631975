import React from 'react';

// Internal Import

// External Import
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { ReactSession } from 'react-client-session';
import { Carousel } from 'react-responsive-carousel';
import ApartmentIcon from '@mui/icons-material/Apartment';

// MUI Import
import { Box, Stack, Skeleton, Typography } from '@mui/material';
import { LoginMemberDataRes, LoginMemberRes, NupDataBeanRes, SliderDataBeanRes, TipeDataBeanRes, TipeDataRes, TipeRes } from '../../../utils/structure/output';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../../components/snackbar';
import { HelmetComponent } from '../../../components/helmet';
import { AppbarAccountComponent } from '../../../components/appbar/account';
import { AccordionNUPComponent } from '../../../components/accordion/nup';
import { FooterComponent } from '../../../components/footer';


interface HomeProjectScreenProps { }
export const HomeProjectScreen: React.FC<HomeProjectScreenProps> = (props) => {

    let navigate = useNavigate();

    const [account, setAccount] = React.useState<undefined | LoginMemberDataRes>(undefined);
    React.useEffect(() => { }, []);

    const [loading, setLoading] = React.useState<boolean>(true);

    const [tipe, setTipe] = React.useState<null | TipeDataRes>(null);
    
    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string, time: number) => {
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        setTimeout(() => { handleCloseSnackbar(); }, 5000);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };

    // NUP
    const [indexNup, setIndexNup] = React.useState<null|number>(null);
    const handelIndexNup = (value: null|number) => { setIndexNup(value); }
    
    // Hit/Consume RestAPI
    const getData = async (dtAccount: LoginMemberDataRes) => {
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL!+process.env.REACT_APP_PATH_TIPE!+'?p_id='+process.env.REACT_APP_PROJECT_ID_CTRLNK +'&sp_id=0&m_id=' + dtAccount.id_member;
            const response: AxiosResponse = await axios.get(url, {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', Authorization: 'Bearer ' + dtAccount.token},
            });

            let dtLoad: TipeRes = response.data;
            if (dtLoad.status === 200) {
                setTipe(dtLoad.data);
                setLoading(false);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                setAccount(undefined);
                ReactSession.set(process.env.REACT_APP_AUTH, undefined);
                handleOpenSnackbar('error', "Unauthorized request, please login again!", 3000);
                navigate('/login', { replace: true });
            } else {
                handleOpenSnackbar('error', "Something problem, please refresh your broweser!", 3000);
            }
        }
    }

    const handleClickItem = (item: TipeDataBeanRes) => {
        navigate('/'+item.next_page+'/'+tipe?.id_project+"/0"+"/"+item.id);
        // navigate('/'+item.next_page+'/'+item.id);
    }

    React.useEffect(() => {
        let dtAccount: LoginMemberDataRes = ReactSession.get(process.env.REACT_APP_AUTH)
        setAccount(dtAccount);
        getData(dtAccount);
    }, []);




    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: { xs: '3.4em', sm: '3.6em', md: '3.8em', lg: '4em', xl: '4.2em' },
        }}>
            <HelmetComponent />
            <SnackbarComponent snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />

            <AppbarAccountComponent page={'home'} account={account} setAccount={setAccount} />

            {loading&&(
                <Skeleton variant='rectangular' sx={{
                    width: '100%',
                    height: { xs: '8em', sm: '10.5em', md: '15em', lg: '17.75em', xl: '17.75em' }
                }}/>
            )}
            {tipe != null && tipe?.sliders != null && tipe.sliders.length > 1 && (
                <Carousel
                    autoPlay
                    infiniteLoop
                    dynamicHeight
                    showStatus={false}
                    showThumbs={false}
                >
                    {tipe.sliders.map((item: SliderDataBeanRes, index: number) => {
                        return (
                            <div>
                                <Box
                                    key={index}
                                    component='img'
                                    alt={item.name}
                                    src={item.image}
                                    sx={{ width: '100%', height: {xs: '12em', sm: '17em', md: '26em', lg: '33.5em', xl: '33.5em'} }}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            )}

            {tipe != null && tipe?.sliders != null && tipe.sliders.length === 1 && (
                <Box
                    component='img'
                    alt={tipe.sliders[0].name}
                    src={tipe.sliders[0].image}
                    sx={{ width: '100%', height: { xs: '12em', sm: '17em', md: '26em', lg: '33.5em', xl: '33.5em'} }}
                />
            )}



            {loading&&(
                <Skeleton variant='rectangular' sx={{ width: '100%', height: '7em'}} />
            )}
            {tipe != null && tipe?.types != null && tipe.types.length > 0 && (
                <Box sx={{
                    pl: '5%',
                    pr: '5%',
                    width: '90%',
                    backgroundColor: '#ffffff',
                    py: { xs: '1em', sm: '1.5em', md: '1.75em', lg: '2.5em', xl: '2.5em' },
                }}>
                    <Typography sx={{
                        mb: '1.5em',
                        color: '#000000',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        fontSize: { xs: '0.8em', sm: '0.9em', md: '1em', lg: '1.25em', xl: '1.25em' },
                    }}>{'Discover ' + process.env.REACT_APP_PROJECT_NAME!}</Typography>
                    {tipe.types.map((item: TipeDataBeanRes, index: number) => {
                        return (
                            <Box
                                key={index}
                                onClick={() => handleClickItem(item)}
                                sx={{
                                    my: '1.5em',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    borderRadius: '10em',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#ffffff',
                                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
                                    px: { xs: '1em', sm: '1.25', md: '1.5m', lg: '2em', xl: '2em' },
                                    py: { xs: '0.5em', sm: '0.6', md: '0.75em', lg: '1em', xl: '1em' },
                                }}
                            >
                                {item.id===2 || item.id===3 || item.id===34 || item.id===35 ? (
                                    <ApartmentIcon sx={{
                                        mr: '0.5em',
                                        width: { xs: '0.5em', sm: '0.6em', md: '0.75em', lg: '1em', xl: '1em' },
                                        height: { xs: '0.5em', sm: '0.6em', md: '0.75em', lg: '1em', xl: '1em' }
                                    }} />
                                ):(
                                    <HomeIcon sx={{
                                        mr: '0.5em',
                                        width: { xs: '0.5em', sm: '0.6em', md: '0.75em', lg: '1em', xl: '1em' },
                                        height: { xs: '0.5em', sm: '0.6em', md: '0.75em', lg: '1em', xl: '1em' }
                                    }} />
                                )}
                                
                                <Typography sx={{
                                    fontWeight: 700,
                                    color: '#000000',
                                    fontFamily: 'Roboto',
                                    fontSize: { xs: '0.5em', sm: '0.6em', md: '0.75em', lg: '1em', xl: '1em' }
                                }}>{item.name}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            )}
            
            {loading&&(
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    my: { xs: '1em', sm: '1.5em', md: '1.75em', lg: '2em', xl: '2em' },
                }}>
                    <Skeleton variant='rectangular' sx={{ 
                        my: '0.5em',
                        width: '70%', 
                        height: '3.2em', 
                        borderRadius: '10em'
                    }} />
                    <Skeleton variant='rectangular' sx={{ 
                        my: '0.5em',
                        width: '70%', 
                        height: '3.2em', 
                        borderRadius: '10em'
                    }} />
                </Box>
            )}
            {tipe != null && tipe?.nups != null && tipe.nups.length > 0 && (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    my: { xs: '1em', sm: '1.5em', md: '1.75em', lg: '2.5em', xl: '2.5em' },
                }}>
                    {tipe.nups.map((item: NupDataBeanRes, index: number) => {
                        return (
                            <AccordionNUPComponent
                                nup={item} 
                                key={index} 
                                idNup={indexNup}
                                account={account} 
                                handleIdNup={handelIndexNup}
                            />
                        )
                    })}
                </Box>
            )}

            <FooterComponent />

        </Stack>
    );
};