import React from 'react';


// Internal Import
import { RegisterMemberReq, ResendOtpReq, VerifyOtpReq } from '../../utils/structure/input';
import { DataRes, OfficeRes, DefaultRes, RegisterMemberRes, RegisterMemberDataRes, LinkRegOfficeRes } from '../../utils/structure/output';

// External Import
import jsPDF from 'jspdf';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError, AxiosResponse } from 'axios';

// MUI Import
import { Box, Stack, Button, Typography, Select, FormControl, MenuItem, InputLabel, SelectChangeEvent, TextField, Stepper, StepLabel, Step, StepContent, Modal, CircularProgress } from '@mui/material';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../components/snackbar';
import { HelmetComponent } from '../../components/helmet';
import { AppbarComponent } from '../../components/appbar';
import { ModalProofOfRegComponent } from '../../components/modal/proofOfReg';
import { ModalOtpComponent } from '../../components/modal/otp';


// Image Import
const imgLogo = `/assets/logo_landscape_${process.env.REACT_APP_PROJECT_CODE}.png`;

interface ListKantor { id: string, name: string }

interface RegisterMemberScreenProps { }
export const RegisterMemberScreen: React.FC<RegisterMemberScreenProps> = (props) => {

    let navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [loadingReg, setLoadingReg] = React.useState<boolean>(false);
    const [loadingOtp, setLoadingOtp] = React.useState<boolean>(false);
    const [loadingResendOtp, setLoadingResendOtp] = React.useState<boolean>(false);

    const [linkRegOffice, setLinkRegOffice] = React.useState<string>("");

    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string, time: number) => {
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        setTimeout(() => { handleCloseSnackbar(); }, 5000);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };

    // Stepper
    const steps = ["Choose Member Group & Office", "Data Information", "Review & Submit Data"];
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const handleBack = () => { 
        setActiveStep((prevActiveStep) => prevActiveStep - 1); 
    };
    const handleNext = () => {
        if (loadingReg) { return }
        if (activeStep == 0) {
            if (group == '') { handleOpenSnackbar('error', 'Member Group is required', 5000); return; }
            if (office == '0') { handleOpenSnackbar('error', 'Office is required', 5000); return; }
            setActiveStep(activeStep + 1);
        }
        if (activeStep == 1) {
            if (nameKtp == '') { handleOpenSnackbar('error', 'Name is required', 5000); return; }
            if (nomorKtp == '') { handleOpenSnackbar('error', 'KTP is required', 5000); return; }
            if (nomorPhone1 == '') { handleOpenSnackbar('error', 'No Handphone 1 is required', 5000); return; }
            if (emailAddress == '') { handleOpenSnackbar('error', 'Email is required', 5000); return; }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) { handleOpenSnackbar('error', 'Invalid email!', 5000); return; }
            if (nomorPhone1 == nomorPhone2) { handleOpenSnackbar('error', 'No HandPhone 1 must not be the same as No HandPhone 2', 5000); return; }
            setActiveStep(activeStep + 1);
        }
        if (activeStep == 2) {
            setLoadingReg(true);
            let params: RegisterMemberReq = {
                group_member: group.toUpperCase(),
                office: office,
                fullname: nameKtp,
                numb_ktp: nomorKtp,
                phone_number1: nomorPhone1,
                phone_number2: nomorPhone2,
                email_address: emailAddress,
                ereg_project: process.env.REACT_APP_PROJECT_ID_EREG!,
                code_project: process.env.REACT_APP_PROJECT_CODE!.toUpperCase(),
                citralink_project: process.env.REACT_APP_PROJECT_ID_CTRLNK!,
            }
            postData(params);
        }
        
    };

    // Form
    const [group, setGroup] = React.useState<string>("0");
    const [office, setOffice] = React.useState<string>("0");
    const [nameKtp, setNameKtp] = React.useState<string>("");
    const [nomorKtp, setNomorKtp] = React.useState<string>("");
    const [nomorPhone1, setNomorPhone1] = React.useState<string>("");
    const [nomorPhone2, setNomorPhone2] = React.useState<string>("");
    const [emailAddress, setEmailAddress] = React.useState<string>("");
    const selectGroup = (event: SelectChangeEvent) => { setGroup(event.target.value as string); };
    const selectOffice = (event: SelectChangeEvent) => { setOffice(event.target.value as string); };
    const changeNameKtp = (event: React.ChangeEvent<HTMLInputElement>) => { setNameKtp(event.target.value as string); }
    const changeNomorKtp = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = (event.target.value as string).replace(/\D/g, "");
        if (inputValue[0] == '0') { inputValue = inputValue.substring(1); }
        if (inputValue.length <= 16) { setNomorKtp(inputValue); }
    }
    const changeNomorPhone1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = (event.target.value as string).replace(/\D/g, "");
        if (inputValue.length > 0) {
            if (inputValue[0] != "0") { inputValue = "0" + inputValue; }
        }
        setNomorPhone1(inputValue);
    }
    const changeNomorPhone2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = (event.target.value as string).replace(/\D/g, "");
        if (inputValue.length > 0) {
            if (inputValue[0] != "0") { inputValue = "0" + inputValue; }
        }
        setNomorPhone2(inputValue);
    }
    const changeEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => { setEmailAddress(event.target.value as string) }
    const [listKantor, setListKantor] = React.useState<any[]>([{ id: "0", name: "- Choose Office -" }]);

    // Register
    const [account, setAccount] = React.useState<RegisterMemberDataRes | null>(null);
    const postData = async (requestData: RegisterMemberReq) => {
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_REGISTER!;
            const response: AxiosResponse = await axios.post(url, requestData, { headers: {'Accept': 'application/json', 'Content-Type': 'application/json'} });
            let registerResponse: RegisterMemberRes = response.data;
            let registerDataResponse: RegisterMemberDataRes = registerResponse.data;
            
            setAccount(registerDataResponse);
            setLoadingReg(false);
            setActiveStep(activeStep + 1);

            handleOpenSnackbar('success', registerResponse.message, 5000);
            setTimeout(()=>{ handleOpenModalReg(); }, 5000);
        } catch (error) {
            setLoadingReg(false);
            const axiosError = error as AxiosError;
            let errResponse: DefaultRes | any = axiosError.response?.data;
            handleOpenSnackbar('error', errResponse?.message, 5000);
        }
    }

    // Load Link Register Office
    const getDataLinkRegOffice = async () => {
        setLoading(true);
        try {
            let link = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_LINK_REG_OFFICE!;
            const response: AxiosResponse = await axios.get(link, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
            let dt: LinkRegOfficeRes = response.data;
            if (dt.status === 200) {
                setLinkRegOffice(dt.data)
                setLoading(false);
            } else {
                setLinkRegOffice("");
                handleOpenSnackbar('error', "Terjadi kendala! Silahkan coba lagi!", 5000);
            }
        } catch (error) {
            setLinkRegOffice("");
            handleOpenSnackbar('error', "Terjadi kendala! Silahkan coba lagi!", 5000);
        }
    }

    // Load Office
    const getData = async (membergroup: string) => {
        try {
            let link = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_OFFICES! + '?group_member=' + membergroup + '&ereg_project='+ process.env.REACT_APP_PROJECT_ID_EREG!+'&code_project='+process.env.REACT_APP_PROJECT_CODE!+'&citralink_project='+process.env.REACT_APP_PROJECT_ID_CTRLNK!
            const response: AxiosResponse = await axios.get(link, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
            let dtOffices: OfficeRes = response.data;
            if (dtOffices.status === 200) {
                let arrListKantor: ListKantor[] = [{ id: "0", name: "- Choose Office -" }];
                dtOffices.data.map((item: DataRes) => {
                    let objListKantor: ListKantor = { id: item.id, name: item.name }
                    arrListKantor.push(objListKantor);
                    return null;
                });
                setListKantor(arrListKantor);
            } else {
                setListKantor([{ id: "0", name: "- Choose Office -" }]);
            }
        } catch (error) {
            setListKantor([{ id: "0", name: "- Choose Office -" }]);
            handleOpenSnackbar('error', "Terjadi kendala! Silahkan coba lagi!", 5000);
        }
    }

    // Modal Bukti Registrasi
    const [modalProofOfReg, setModalProofOfReg] = React.useState<boolean>(false);
    const handelOpenModalProofOfReg = () => { setModalProofOfReg(true); }
    const handelCloseModalProofOfReg = () => { 
        setModalProofOfReg(false); 
        navigate(-1);
    }
    const handleDownload = async () => {
        const widthInMM = 437;
        const heightInMM = 251;
        const validasi = account?.validasi;
        const regDate = `<b>Tanggal Registrasi : </b>${account?.date_time}`
        const pdf = new jsPDF({ unit: 'mm', format: [widthInMM, heightInMM], orientation: 'landscape' });
        let withHtml = `
            <div style="padding: 10px 20px; width: 397px; height: 231px;">
                <div style="display: flex; flex-direction: row; justify-content: space-between"; align-items: top>
                    <img 
                        src=${imgLogo}
                        style="width: 76px; object-fit: contain;" />
                    <p style="font-size: 5px; font-style: italic;">Validasi: ${validasi}</p>
                </div>
                <table style="width: 397px;">
                    <tr>
                        <td colspan="4" style="text-align: center; font-size: 10px; font-weight: bold; padding-top: 2px; padding-bottom: 10px;">
                            <div style="display: flex; flex-direction: row; justify-content: center;">
                                <div>BUKTI REGISTRASI</div>
                                <div>&nbsp; MEMBER</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" style="font-size: 8px; padding-bottom: 8px;">${regDate}</td>
                    </tr>
                    <tr style="font-size: 8px;">
                        <td style="width: 13px;">1.</td>
                        <td style="width: 100px;">Nama Sales (sesuai KTP)</td>
                        <td>:</td>
                        <td>${account?.fullname}</td>
                    </tr>
                    <tr style="font-size: 8px;">
                        <td>2.</td>
                        <td>No KTP Sales</td>
                        <td>:</td>
                        <td>${account?.ktp_number}</td>
                    </tr>
                    <tr style="font-size: 8px;">
                        <td>3.</td>
                        <td>Nama Kantor</td>
                        <td>:</td>
                        <td>${account?.office}</td>
                    </tr>
                    <tr style="font-size: 8px;">
                        <td>4.</td>
                        <td>Nomor HP 1</td>
                        <td>:</td>
                        <td>${account?.phone_number1}</td>
                    </tr>
                    <tr style="font-size: 8px;">
                        <td>5.</td>
                        <td>Nomor HP</td>
                        <td>:</td>
                        <td>${account?.phone_number2 == "" ? "-" : account?.phone_number2}</td>
                    </tr>
                    <tr style="font-size: 8px;">
                        <td>6.</td>
                        <td>Email</td>
                        <td>:</td>
                        <td>${account?.email_address}</td>
                    </tr>
                </table>
                <div style="font-size: 7px; font-style: italic; padding-top: 8px;">
                    Terimakasih telah melakukan Registrasi / Update Member <br/>
                    Mohon menyertakan Member setiap kali transaksi
                </div>
                <div style="font-size: 7px; text-align: right">${account?.date}</div>
            </div>
        `;

        let content = document.createElement("div");
        content.innerHTML = withHtml;
        pdf.html(content, {
            callback: () => {
                pdf.save(`BuktiRegistrasiMember_${account?.fullname}_${process.env.REACT_APP_PROJECT_CODE!}.pdf`);
            },
        });
    }


    // Modal OTP
    const [otp, setOtp] = React.useState<string>("");
    const [modalOtpReg, setModalOtpReg] = React.useState<boolean>(false);
    const handleOpenModalReg = () => { setModalOtpReg(true); }
    const handleCloseModalReg = () => { 
        setOtp("");
        setModalOtpReg(false);
        handelOpenModalProofOfReg();
    }
    const handleResendOtp = async() => {
        setLoadingResendOtp(true);
        let requestData: ResendOtpReq = {
            id: account?.id!,
            office: account?.office!,
            code_project: process.env.REACT_APP_PROJECT_CODE!
        }
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_RESEND_OTP!;
            const response: AxiosResponse = await axios.post(url, requestData, { headers: {'Accept': 'application/json', 'Content-Type': 'application/json'} });
            let resendOtpResponse: DefaultRes = response.data;

            if (resendOtpResponse.status == 200) {
                setLoadingResendOtp(false);
                handleOpenSnackbar('success', resendOtpResponse.message, 5000);
            } else {
                setLoadingResendOtp(false);
                handleOpenSnackbar('error', resendOtpResponse.message, 5000);
            }
        } catch (error) {
            setLoadingResendOtp(false);
            const axiosError = error as AxiosError;
            let errResponse: DefaultRes | any = axiosError.response?.data;
            handleOpenSnackbar('error', errResponse?.message, 5000);
        }
    }
    const handleSubmitOtp = async() => {
        setLoadingOtp(true);
        let requestData: VerifyOtpReq = {
            id: account?.id!,
            otp: otp,
            ereg_project: process.env.REACT_APP_PROJECT_ID_EREG!,
            code_project: process.env.REACT_APP_PROJECT_CODE!,
            citralink_project: process.env.REACT_APP_PROJECT_ID_CTRLNK!,
        }
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_VERIFY_OTP!;
            const response: AxiosResponse = await axios.post(url, requestData, { headers: {'Accept': 'application/json', 'Content-Type': 'application/json'} });
            let otpResponse: DefaultRes = response.data;

            setLoadingOtp(false);
            if (otpResponse.status == 200) {
                handleCloseModalReg();
            } else {
                handleOpenSnackbar('error', otpResponse.message, 3000);
            }
            
        } catch (error) {
            setLoadingOtp(false);
            const axiosError = error as AxiosError;
            let errResponse: DefaultRes | any = axiosError.response?.data;
            handleOpenSnackbar('error', errResponse?.message, 5000);
            handleCloseModalReg();
        }
    }

    const handleRegOffice = () => {
        window.open(linkRegOffice, "_self");
        // window.open("https://bit.ly/CitraLinkKantorAgent", "_blank");
        // window.open("https://bit.ly/Update2024KantorAgent", "_blank");
    }

    React.useEffect(() => {  getDataLinkRegOffice(); }, []);

    React.useEffect(() => {
        setOffice("0");
        if (group !== "0") {
            setListKantor([{ id: "0", name: "Please wait..." }]);
            setTimeout(() => { getData(group); }, 1000);
        }
    }, [group]);

    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            minHeight: '100vh',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: '#F1F1F7'
        }}>
            <HelmetComponent />
            <AppbarComponent />

            <SnackbarComponent
                snackbar={snackbar} 
                handleCloseSnackbar={handleCloseSnackbar} 
            />
            
            <ModalProofOfRegComponent
                account={account!}
                open={modalProofOfReg}
                handleDownload={handleDownload}
                handleClose={handelCloseModalProofOfReg}
            />

            <ModalOtpComponent
                otp={otp}
                setOtp={setOtp}
                open={modalOtpReg}
                isLoading={loadingOtp}
                handleResend={handleResendOtp}
                handleSubmit={handleSubmitOtp}
                phone={account?.phone_number1!}
                handleClose={handleCloseModalReg}
                isLoadingResend={loadingResendOtp}
            />

            <Box sx={{
                my: '3em',
                height: '100%',
                width: { xs: '90%', sm: '85%', md: '80%', lg: '65%', xl: '70%' }
            }}>
                <Typography sx={{
                    mb: '0.2em',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontFamily: 'Open Sans',
                    fontSize: { xs: '1.6em', sm: '1.8em', md: '2em', lg: '2em', xl: '2.2em' }
                }}>Registration Member</Typography>
                <Typography sx={{
                    fontWeight: 'light',
                    textAlign: 'center',
                    fontFamily: 'Open Sans',
                    fontSize: { xs: '0.8em', sm: '1em', md: '1em', lg: '1em', xl: '1.2em' },
                }}>Mulailah petualanganmu sekarang! Bergabunglah bersama kami dan daftarkan dirimu untuk memasarkan properti terbaik dari Ciputra Group.</Typography>
            </Box>

            
            {loading?(
                <Stack sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F1F1F7'
                }}> <CircularProgress /> </Stack>
            ):(
                <Box sx={{
                    mb: '3.5em',
                    borderRadius: '0.8em',
                    backgroundColor: '#ffffff',
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
                    width: { xs: '90%', sm: '80%', md: '60%', lg: '40%', xl: '30%' }
                }}>
                    <Stepper activeStep={activeStep} orientation="vertical" sx={{ px: '1.5em', py: '1em' }}>
                        {steps.map((step, index) => (
                            <Step key={index}>
                                <StepLabel>{step}</StepLabel>
                                <StepContent>
                                    {index === 0 && (
                                        <Box sx={{ my: '1.5em' }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="group">Member Group</InputLabel>
                                                <Select
                                                    id="group"
                                                    value={group}
                                                    labelId="group"
                                                    label="Member Group"
                                                    onChange={selectGroup}
                                                >
                                                    <MenuItem value={"0"}>- Choose Member Group -</MenuItem>
                                                    <MenuItem value={"i"}>{"Sales InHouse"}</MenuItem>
                                                    <MenuItem value={"a"}>{"Agent Jabodetabek"}</MenuItem>
                                                    <MenuItem value={"b"}>{"Agent Non Jabodetabek"}</MenuItem>
                                                    <MenuItem value={"c"}>{"Broker Tradisional"}</MenuItem>
                                                    <MenuItem value={"k"}>{"Klub Citra"}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth sx={{ mt: '2em' }}>
                                                <InputLabel id="group">Kantor</InputLabel>
                                                <Select
                                                    id="office"
                                                    label="office"
                                                    value={office}
                                                    labelId="group"
                                                    onChange={selectOffice}
                                                >
                                                    {listKantor.map((item, index) => {
                                                        return (<MenuItem key={index} value={item.id}>{item.name}</MenuItem>);
                                                    })}
                                                </Select>
                                            </FormControl>
                                            
                                            <Typography sx={{
                                                mt: '1em',
                                                fontWeight: 500,
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '0.55em', sm: '0.65em', md: '0.0.75', lg: '0.85em', xl: '0.85em' },
                                            }}>Jika kantor anda belum ada di list <span style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }} onClick={handleRegOffice}>klik disini</span></Typography>
                                        </Box>
                                    )}
                                    {index === 1 && (
                                        <Box sx={{ my: '1.5em' }}>
                                            <TextField
                                                fullWidth
                                                type='text'
                                                value={nameKtp}
                                                variant="outlined"
                                                id="outlined-basic"
                                                onChange={changeNameKtp}
                                                label="*Nama Sales (sesuai KTP)*"
                                                placeholder="*Nama Sales (sesuai KTP)"
                                            />
                                            <TextField
                                                fullWidth
                                                type='text'
                                                sx={{ mt: '2em' }}
                                                value={nomorKtp}
                                                variant="outlined"
                                                id="outlined-basic"
                                                onChange={changeNomorKtp}
                                                label="*No KTP Sales (sesuai KTP)*"
                                                placeholder="*No KTP Sales (sesuai KTP)"
                                            />
                                            <TextField
                                                fullWidth
                                                type='text'
                                                sx={{ mt: '2em' }}
                                                value={nomorPhone1}
                                                variant="outlined"
                                                id="outlined-basic"
                                                label="*No HandPhone 1"
                                                onChange={changeNomorPhone1}
                                                placeholder="*No HandPhone 1"
                                            />
                                            <TextField
                                                fullWidth
                                                type='text'
                                                sx={{ mt: '2em' }}
                                                value={nomorPhone2}
                                                variant="outlined"
                                                id="outlined-basic"
                                                label="No HandPhone 2"
                                                onChange={changeNomorPhone2}
                                                placeholder="*No HandPhone 2"
                                            />
                                            <TextField
                                                fullWidth
                                                type='text'
                                                label="*Email"
                                                sx={{ mt: '2em' }}
                                                variant="outlined"
                                                id="outlined-basic"
                                                placeholder="*Email"
                                                value={emailAddress}
                                                onChange={changeEmailAddress}
                                            />
                                            <Typography sx={{
                                                mt: '3em',
                                                fontStyle: 'italic',
                                                fontFamily: 'Roboto',
                                                fontSize: { xs: '0.65em', sm: '0.65em', md: '0.7em', lg: '0.8em', xl: '0.8em' },
                                            }}>{"* Data wajib diisi"}</Typography>
                                        </Box>
                                    )}
                                    {index == 2 && (
                                        <Box sx={{ mt: '1em', mb: '1.5em' }}>
                                            <Box>
                                                <Typography sx={{
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                                }}>Nama Kantor</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                                }}>{(listKantor.find(of => of.id === office) || {}).name}</Typography>
                                            </Box>
                                            <Box sx={{ mt: '0.5em' }}>
                                                <Typography sx={{
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                                }}>Nama Sales</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                                }}>{nameKtp}</Typography>
                                            </Box>
                                            <Box sx={{ mt: '0.5em' }}>
                                                <Typography sx={{
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                                }}>No KTP Sales</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                                }}>{nomorKtp}</Typography>
                                            </Box>
                                            <Box sx={{ mt: '0.5em' }}>
                                                <Typography sx={{
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                                }}>No Handphone 1</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                                }}>{nomorPhone1}</Typography>
                                            </Box>
                                            <Box sx={{ mt: '0.5em' }}>
                                                <Typography sx={{
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                                }}>No Handphone 2</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                                }}>{nomorPhone2 == '' ? '-' : nomorPhone2}</Typography>
                                            </Box>
                                            <Box sx={{ mt: '0.5em' }}>
                                                <Typography sx={{
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                                                }}>Email</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                                                }}>{emailAddress}</Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    <Box sx={{ mb: 2 }}>
                                        <Button
                                            size='medium'
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled={loadingReg || account != null}
                                        >
                                            {index === steps.length - 1 
                                                ? loadingReg?"Loading":"Submit"
                                                : 'Continue'}
                                        </Button>
                                        {index != 0 && !loadingReg && (<Button size='medium' onClick={handleBack} sx={{ mt: 1, mr: 1 }}>Back</Button>)}
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            )}
        </Stack >
    );
};