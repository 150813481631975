import React from "react";

// Internal Import

// External Import
import MapIcon from '@mui/icons-material/Map';
import PlaceIcon from '@mui/icons-material/Place';
import ShareIcon from '@mui/icons-material/Share';
import DiscountIcon from '@mui/icons-material/Discount';
import LanguageIcon from '@mui/icons-material/Language';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

// MUI Import
import { Box, Typography } from "@mui/material";
import { AccordionDataBeenRes, DetailDataBeanRes, DetailDataRes, SharingDataRes, StockDataBeanRes, TourDataBeanRes } from "../../../utils/structure/output";

interface AccordionDetailComponentProps {
    idDetail: null | number
    cl: AccordionDataBeenRes
    handleIdDetail: (value: null | number) => void
    data: DetailDataRes|SharingDataRes
}
export const AccordionDetailComponent: React.FC<AccordionDetailComponentProps> = ({
    cl, data, idDetail, handleIdDetail
}) => {
    const handleItemAccordion = () => {
        if (idDetail == cl.id) { handleIdDetail(null); }
        else { handleIdDetail(cl.id); }
    }

    const handleClistItemAccourdion = (value: string) => { window.open(value, "_self"); }

    return (
        <Box>
            <Box
                onClick={handleItemAccordion}
                sx={{
                    mt: '1em',
                    display: 'flex',
                    cursor: 'pointer',
                    borderRadius: '0.5em',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
                    px: { xs: '1em', sm: '1.25', md: '1.5m', lg: '2em', xl: '2em' },
                    py: { xs: '0.5em', sm: '0.6', md: '0.75em', lg: '1em', xl: '1em' },
                }}
            >
                {cl.id == 1 && (
                    <DiscountIcon sx={{
                        width: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                        height: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                    }} />
                )}
                {cl.id == 2 && (
                    <MapIcon sx={{
                        width: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                        height: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                    }} />
                )}
                {cl.id == 3 && (
                    <PlaceIcon sx={{
                        width: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                        height: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                    }} />
                )}
                {cl.id == 4 && (
                    <EmojiObjectsIcon sx={{
                        width: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                        height: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                    }} />
                )}
                {cl.id == 5 && (
                    <VideoLibraryIcon sx={{
                        width: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                        height: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                    }} />
                )}
                {cl.id == 6 && (
                    <LanguageIcon sx={{
                        width: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                        height: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.8em', xl: '0.8em' },
                    }} />
                )}
                <Typography sx={{
                    ml: '0.5em',
                    fontWeight: 700,
                    color: '#000000',
                    fontFamily: 'Roboto',
                    fontSize: { xs: '0.5em', sm: '0.6em', md: '0.75em', lg: '1em', xl: '1em' }
                }}>{cl.name}</Typography>
            </Box>
            {idDetail == cl.id && (cl.id == 1) && (
                <Box sx={{
                    mb: '2em',
                    p: '1.5em',
                    display: 'flex',
                    overflow: 'auto',
                    flexDirection: 'row',
                    backgroundColor: '#EAEAEA',
                }}>
                    {data.pricelists.map((item: DetailDataBeanRes, index: number) => {
                        return (
                            <Box sx={{ mx: '1em', boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' }}>
                                <Box
                                    sx={{
                                        px: '1em',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        backgroundColor: '#ECFFFA',
                                        width: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' },
                                        height: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' }
                                    }}
                                >
                                    <PictureAsPdfIcon sx={{
                                        color: '#000000',
                                        width: { xs: '1.75em', sm: '2em', md: '2.25em', lg: '2.5em', xl: '2.5em' },
                                        height: { xs: '1.75em', sm: '2em', md: '2.25em', lg: '2.5em', xl: '2.5em' }
                                    }} />
                                    <Typography sx={{
                                        mt: '0.75em',
                                        color: '#000000',
                                        textAlign: 'center',
                                        fontFamily: 'Roboto',
                                        fontSize: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.7em', xl: '0.7em' },
                                    }}>{item.title}</Typography>
                                </Box>
                                <Typography onClick={() => handleClistItemAccourdion(item.file)} sx={{
                                    py: '0.2em',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    fontFamily: 'Roboto',
                                    backgroundColor: '#008060',
                                    fontSize: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '1em', xl: '1em' },
                                }}>Select</Typography>
                            </Box>
                        )
                    })}
                </Box>
            )}

            {idDetail == cl.id && (cl.id == 2) && (
                <Box sx={{
                    mb: '2em',
                    p: '1.5em',
                    display: 'flex',
                    overflow: 'auto',
                    flexDirection: 'row',
                    backgroundColor: '#EAEAEA',
                }}>
                    {data.ebrochures.map((item: DetailDataBeanRes, index: number) => {
                        return (
                            <Box sx={{ mx: '1em', boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' }}>
                                <Box
                                    sx={{
                                        px: '1em',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        backgroundColor: '#ECFFFA',
                                        width: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' },
                                        height: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' }
                                    }}
                                >
                                    <PictureAsPdfIcon sx={{
                                        color: '#000000',
                                        width: { xs: '1.75em', sm: '2em', md: '2.25em', lg: '2.5em', xl: '2.5em' },
                                        height: { xs: '1.75em', sm: '2em', md: '2.25em', lg: '2.5em', xl: '2.5em' }
                                    }} />
                                    <Typography sx={{
                                        mt: '0.5em',
                                        color: '#000000',
                                        textAlign: 'center',
                                        fontFamily: 'Roboto',
                                        fontSize: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.7em', xl: '0.7em' },
                                    }}>{item.title}</Typography>
                                </Box>
                                <Typography onClick={() => handleClistItemAccourdion(item.file)} sx={{
                                    py: '0.2em',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    fontFamily: 'Roboto',
                                    backgroundColor: '#008060',
                                    fontSize: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '1em', xl: '1em' },
                                }}>Select</Typography>
                            </Box>
                        )
                    })}
                </Box>
            )}

            {idDetail == cl.id && cl.id == 3 && (
                <Box sx={{
                    mb: '2em',
                    p: '1.5em',
                    display: 'flex',
                    overflow: 'auto',
                    flexDirection: 'row',
                    backgroundColor: '#EAEAEA',
                }}>
                    {data.stocks.map((item: StockDataBeanRes, index: number) => {
                        return (
                            <Box sx={{ mx: '1em', boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' }}>
                                <Box
                                    component='img'
                                    alt={item.title}
                                    src={item.siteplan}
                                    sx={{
                                        objectFit: 'cover',
                                        width: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' },
                                        height: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' }
                                    }}
                                />
                                <Typography onClick={() => handleClistItemAccourdion(item.link)} sx={{
                                    py: '0.2em',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    fontFamily: 'Roboto',
                                    backgroundColor: '#008060',
                                    fontSize: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '1em', xl: '1em' },
                                }}>Select</Typography>
                            </Box>
                        )
                    })}
                </Box>
            )}

            {idDetail == cl.id && (cl.id == 4) && (
                <Box sx={{
                    p: '1.5em',
                    mb: '2em',
                    display: 'flex',
                    overflow: 'auto',
                    flexDirection: 'row',
                    backgroundColor: '#EAEAEA',
                }}>
                    {data.product_knowledges.map((item: DetailDataBeanRes, index: number) => {
                        return (
                            <Box sx={{ mx: '1em', boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' }}>
                                <Box
                                    sx={{
                                        px: '1em',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        backgroundColor: '#ECFFFA',
                                        width: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' },
                                        height: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' }
                                    }}
                                >
                                    <PictureAsPdfIcon sx={{
                                        color: '#000000',
                                        width: { xs: '1.75em', sm: '2em', md: '2.25em', lg: '2.5em', xl: '2.5em' },
                                        height: { xs: '1.75em', sm: '2em', md: '2.25em', lg: '2.5em', xl: '2.5em' }
                                    }} />
                                    <Typography sx={{
                                        mt: '0.5em',
                                        color: '#000000',
                                        textAlign: 'center',
                                        fontFamily: 'Roboto',
                                        fontSize: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.7em', xl: '0.7em' },
                                    }}>{item.title}</Typography>
                                </Box>
                                <Typography onClick={() => handleClistItemAccourdion(item.file)} sx={{
                                    py: '0.2em',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    fontFamily: 'Roboto',
                                    backgroundColor: '#008060',
                                    fontSize: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '1em', xl: '1em' },
                                }}>Select</Typography>
                            </Box>
                        )
                    })}
                </Box>
            )}

            {idDetail == cl.id && (cl.id == 5) && (
                <Box sx={{
                    p: '1.5em',
                    mb: '2em',
                    display: 'flex',
                    overflow: 'auto',
                    flexDirection: 'row',
                    backgroundColor: '#EAEAEA',
                }}>
                    {data.video_tours.map((item: TourDataBeanRes, index: number) => {
                        return (
                            <Box sx={{ mx: '1em', boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' }}>
                                <Box
                                    sx={{
                                        px: '1em',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        backgroundColor: '#ECFFFA',
                                        width: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' },
                                        height: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' }
                                    }}
                                >
                                    <ShareIcon sx={{
                                        color: '#000000',
                                        width: { xs: '1.75em', sm: '2em', md: '2.25em', lg: '2.5em', xl: '2.5em' },
                                        height: { xs: '1.75em', sm: '2em', md: '2.25em', lg: '2.5em', xl: '2.5em' }
                                    }} />
                                    <Typography sx={{
                                        mt: '0.5em',
                                        color: '#000000',
                                        textAlign: 'center',
                                        fontFamily: 'Roboto',
                                        fontSize: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.7em', xl: '0.7em' },
                                    }}>{item.title}</Typography>
                                </Box>
                                <Typography onClick={() => handleClistItemAccourdion(item.link)} sx={{
                                    py: '0.2em',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    fontFamily: 'Roboto',
                                    backgroundColor: '#008060',
                                    fontSize: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '1em', xl: '1em' },
                                }}>Select</Typography>
                            </Box>
                        )
                    })}
                </Box>
            )}

            {idDetail == cl.id && (cl.id == 6) && (
                <Box sx={{
                    p: '1.5em',
                    mb: '2em',
                    display: 'flex',
                    overflow: 'auto',
                    flexDirection: 'row',
                    backgroundColor: '#EAEAEA',
                }}>
                    {data.virtual_tours.map((item: TourDataBeanRes, index: number) => {
                        return (
                            <Box sx={{ mx: '1em', boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        backgroundColor: '#ECFFFA',
                                        width: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' },
                                        height: { xs: '7em', sm: '8em', md: '9em', lg: '10em', xl: '10em' }
                                    }}
                                >
                                    <ShareIcon sx={{
                                        color: '#000000',
                                        width: { xs: '2.3em', sm: '2.6em', md: '3em', lg: '3.3em', xl: '3.3em' },
                                        height: { xs: '2.3em', sm: '2.6em', md: '3em', lg: '3.3em', xl: '3.3em' }
                                    }} />
                                    <Typography sx={{
                                        mt: '0.5em',
                                        color: '#000000',
                                        textAlign: 'center',
                                        fontFamily: 'Roboto',
                                        fontSize: { xs: '0.4em', sm: '0.5em', md: '0.6em', lg: '0.7em', xl: '0.7em' },
                                    }}>{item.title}</Typography>
                                </Box>
                                <Typography onClick={() => handleClistItemAccourdion(item.link)} sx={{
                                    py: '0.2em',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    fontFamily: 'Roboto',
                                    backgroundColor: '#008060',
                                    fontSize: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '1em', xl: '1em' },
                                }}>Select</Typography>
                            </Box>
                        )
                    })}
                </Box>
            )}


        </Box>
    );
};