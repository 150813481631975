import React from 'react';

// Internal Import
import { HelmetComponent } from '../../components/helmet';
import { AppbarComponent } from '../../components/appbar';
import { CheckMemberReq } from '../../utils/structure/input';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../components/snackbar';
import { DefaultRes, CheckMemberDataRes, CheckMemberRes } from '../../utils/structure/output';

// Extenal Import
import axios, { AxiosResponse } from 'axios';

import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';

interface CheckMemberScreenProps { }
export const CheckMemberScreen: React.FC<CheckMemberScreenProps> = (props) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [checkData, setCheckData] = React.useState<CheckMemberDataRes | undefined>(undefined);

    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string) => {
        let count: number = 2000;
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        if (newStatus == 'success') { count = 1000; }
        setTimeout(() => { handleCloseSnackbar(); }, count);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };

    const [value, setValue] = React.useState<string>("");
    const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value as string;
        setValue(inputValue);
    }

    const handleCheck = async () => {
        if (value == "") { handleOpenSnackbar('warning', "Form tidak boleh kosong!"); return; }
        setIsLoading(true);
        let requestData: CheckMemberReq = { value: value, ereg_project: process.env.REACT_APP_PROJECT_ID_EREG! }
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_CHECK_MEMBER!;
            const response: AxiosResponse = await axios.post(url, requestData, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
            let dtCheck: CheckMemberRes = response.data;

            if (dtCheck.status === 200) {
                setCheckData(dtCheck.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setCheckData(undefined);
                handleOpenSnackbar('warning', "Member tidak ditemukan");
            }
            
        } catch (error) {
            setIsLoading(false);
            setCheckData(undefined);
            handleOpenSnackbar('warning', "Member tidak ditemukan");
        }
    }

    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            minHeight: '100vh',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: '#F1F1F7'
        }}>
            <HelmetComponent />
            <AppbarComponent />

            <SnackbarComponent
                snackbar={snackbar}
                handleCloseSnackbar={handleCloseSnackbar}
            />

            <Box sx={{
                mt: '3em',
                mb: '1em',
                height: '100%',
                width: { xs: '90%', sm: '85%', md: '80%', lg: '65%', xl: '70%' }
            }}>
                <Typography sx={{
                    mb: '0.2em',
                    fontWeight: '500',
                    fontFamily: 'Open Sans',
                    fontSize: { xs: '1.2em', sm: '1.3em', md: '1.5em', lg: '1.5em', xl: '1.7em' }
                }}>Check Member</Typography>
            </Box>

            <Box sx={{
                mb: '1.5em',
                borderRadius: '0.8em',
                backgroundColor: '#ffffff',
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
                width: { xs: '90%', sm: '80%', md: '70%', lg: '70%', xl: '60%' }
            }}>
                <Box sx={{
                    mx: 4,
                    mb: '0.8em',
                    mt: '1.5em',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                }}>
                    <Typography sx={{
                        fontFamily: 'Open Sans',
                        fontSize: { xs: '0.55em', sm: '0.6em', md: '0.7em', lg: '0.8em', xl: '0.8em' },
                    }}>Cari Berdasarkan</Typography>
                    <TextField
                        fullWidth
                        type='text'
                        size='small'
                        value={value}
                        color='success'
                        variant="outlined"
                        id="outlined-basic"
                        onChange={changeValue}
                        label="Nama Lengkap atau Nomor KTP"
                        placeholder="Nama Lengkap atau Nomor KTP"
                        sx={{ mx: { xs: '0.8em', sm: '1em', md: '1.2em', lg: '1em', xl: '1.em' } }}
                    />
                    <Button
                        sx={{ height: '3em' }}
                        onClick={handleCheck} variant="outlined" size='small' color='success' disabled={isLoading}>
                        {isLoading ? "Loading" : "Cari"}
                    </Button>
                </Box>
            </Box>

            {isLoading && (
                <Stack sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <CircularProgress />
                </Stack>
            )}

            {!isLoading && checkData != undefined && (
                <Box sx={{
                    mb: '3.5em',
                    borderRadius: '0.8em',
                    backgroundColor: '#ffffff',
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
                    width: { xs: '90%', sm: '80%', md: '70%', lg: '70%', xl: '60%' }
                }}>
                    <Box sx={{ mt: '2em', mb: '2em', mx: 4 }}>

                        <Box sx={{ mt: '0.5em' }}>
                            <Typography sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                            }}>Nama Sales</Typography>
                            <Typography sx={{
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                            }}>{checkData.fullname}</Typography>
                        </Box>
                        <Box sx={{ mt: '0.5em' }}>
                            <Typography sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                            }}>No Handphone 1</Typography>
                            <Typography sx={{
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                            }}>{checkData.phone_number1}</Typography>
                        </Box>
                        <Box sx={{ mt: '0.5em' }}>
                            <Typography sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                            }}>No Handphone 2</Typography>
                            <Typography sx={{
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                            }}>{checkData.phone_number2 == '' ? '-' : checkData.phone_number2}</Typography>
                        </Box>
                        <Box sx={{ mt: '0.5em' }}>
                            <Typography sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                            }}>Email</Typography>
                            <Typography sx={{
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                            }}>{checkData.email_address}</Typography>
                        </Box>
                        <Box sx={{ mt: '0.5em' }}>
                            <Typography sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                            }}>Kantor</Typography>
                            <Typography sx={{
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                            }}>{checkData.office}</Typography>
                        </Box>
                        <Box sx={{ mt: '0.5em' }}>
                            <Typography sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.55em', sm: '0.6em', md: '0.65em', lg: '0.7em', xl: '0.7em' },
                            }}>Status</Typography>
                            <Typography sx={{
                                fontFamily: 'Open Sans',
                                fontSize: { xs: '0.75em', sm: '0.8em', md: '0.85em', lg: '1em', xl: '1em' },
                            }}>{checkData.status?"Active":"Non-Active"}</Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </Stack>
    );
};