import React from "react";
// External Import
import { useLocation } from "react-router-dom";

export const getAvatarName = (name: string) => {
    const nameParts = name.split(' ');
    let avatarName = '';
    if (nameParts.length > 0) {
      avatarName += nameParts[0].charAt(0).toUpperCase();
      if (nameParts.length > 1) {
        avatarName += nameParts[1].charAt(0).toUpperCase();
      }
    }
    return avatarName;
  };

export const useFindPath = () => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = React.useState<string>("");
    React.useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location]);
    return currentPath;
};

export const Nl2br = (str: string) => {
  return str.split('\n').map((line: any, index: number) => {
    return index === 0 ? line : [<br key={index} />, line];
  });
}
