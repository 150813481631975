import React from 'react';

// Internal Import
import { FooterComponent } from '../../components/footer';
import { HelmetComponent } from '../../components/helmet';
import { AppbarAccountComponent } from '../../components/appbar/account';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../components/snackbar';
import { ListEventDataRes, ListEventRes, LoginMemberDataRes } from '../../utils/structure/output';

// External Import
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';

// MUI Import
import { Box, Stack, Skeleton, Typography } from '@mui/material';


interface EventScreenProps { }
export const EventScreen: React.FC<EventScreenProps> = (props) => {

    let navigate = useNavigate();

    const [account, setAccount] = React.useState<undefined | LoginMemberDataRes>(undefined);
    React.useEffect(() => { }, []);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [event, setEvent] = React.useState<undefined | ListEventDataRes[]>([]);
    
    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string, time: number) => {
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        setTimeout(() => { handleCloseSnackbar(); }, 5000);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };
    
    // Hit/Consume RestAPI
    const getData = async (dtAccount: LoginMemberDataRes) => {
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL!+process.env.REACT_APP_PATH_LIST_EVENT!+'?p_id='+process.env.REACT_APP_PROJECT_ID_CTRLNK;
            const response: AxiosResponse = await axios.get(url, {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', Authorization: 'Bearer ' + dtAccount.token},
            });

            let dtLoad: ListEventRes = response.data;
            console.log("ListEventRes", dtLoad);
            if (dtLoad.status === 200) {
                setEvent(dtLoad.data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                setAccount(undefined);
                ReactSession.set(process.env.REACT_APP_AUTH, undefined);
                handleOpenSnackbar('error', "Unauthorized request, please login again!", 3000);
                navigate('/login', { replace: true });
            } else {
                handleOpenSnackbar('error', "Something problem, please refresh your broweser!", 3000);
            }
        }
    }

    const handleClickItem = (item: ListEventDataRes) => {
        navigate('/event/'+item.id);
    }

    React.useEffect(() => {
        let dtAccount: LoginMemberDataRes = ReactSession.get(process.env.REACT_APP_AUTH)
        setAccount(dtAccount);
        setLoading(true);
        getData(dtAccount);
    }, []);

    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: { xs: '3.4em', sm: '3.6em', md: '3.8em', lg: '4em', xl: '4.2em' },
        }}>
            <HelmetComponent />
            <SnackbarComponent snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />

            <AppbarAccountComponent page={'home'} account={account} setAccount={setAccount} />

            <Box sx={{
                pl: '5%',
                pr: '5%',
                width: '90%',
                backgroundColor: '#ffffff',
                py: { xs: '1em', sm: '1.5em', md: '1.75em', lg: '2.5em', xl: '2.5em' },
            }}>
                <Typography sx={{
                    mb: '1.5em',
                    color: '#000000',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                    fontSize: { xs: '0.8em', sm: '0.9em', md: '1em', lg: '1.25em', xl: '1.25em' },
                }}>{'Events'}</Typography>
                {!loading && event != undefined && event.length == 0 && (
                    <Typography sx={{
                        mb: '1.5em',
                        color: '#000000',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        fontSize: { xs: '0.55em', sm: '0.65em', md: '0.75em', lg: '1em', xl: '1em' },
                    }}>{'Tidak ada event yang tersedia.'}</Typography>
                )}
                {loading&&(
                    <Skeleton variant='rectangular' sx={{ width: '100%', height: '7em', borderRadius: '1em'}} />
                )}

                {!loading && event != undefined && event.length > 0 && (
                    <>
                        {event.map((item: ListEventDataRes, index: number) => {
                            return (
                                <Box
                                    key={index}
                                    onClick={() => handleClickItem(item)}
                                    sx={{
                                        my: '1.5em',
                                        display: 'flex',
                                        cursor: 'pointer',
                                        borderRadius: '1em',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        backgroundColor: '#ffffff',
                                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
                                        px: { xs: '1em', sm: '1.25', md: '1.5m', lg: '2em', xl: '2em' },
                                        py: { xs: '0.5em', sm: '0.6', md: '0.75em', lg: '1em', xl: '1em' },
                                    }}
                                >
                                    <Typography sx={{
                                        fontWeight: 700,
                                        color: '#000000',
                                        fontFamily: 'Roboto',
                                        fontSize: { xs: '0.8em', sm: '0.9em', md: '1em', lg: '1.25em', xl: '1.25em' },
                                    }}>{item.name}</Typography>
                                    {item.is_show?(
                                        <Typography sx={{
                                            color: '#000000',
                                            fontFamily: 'Roboto',
                                            fontSize: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '0.9em', xl: '0.9em' }
                                        }}>Jumlah terdaftar: {item.count}</Typography>
                                    ):(
                                        <Typography sx={{
                                            color: '#000000',
                                            fontFamily: 'Roboto',
                                            fontSize: { xs: '0.7em', sm: '0.8em', md: '0.9em', lg: '0.9em', xl: '0.9em' }
                                        }}>- Click to register -</Typography>
                                    )}
                                </Box>
                            )
                        })}
                    </>
                )}
            </Box>

            <FooterComponent />

        </Stack>
    );
};