import React from 'react';

// Internal Import
import { DetailEventDataRes, LoginMemberDataRes } from '../../../utils/structure/output';

// MUI Import
import { Box, Modal, Button } from '@mui/material';


// Image Import

export type ModalEventRegComponentProps = {
    open: boolean
    handleClose: ()=>void
    event: DetailEventDataRes
    account: undefined|LoginMemberDataRes
}
export const ModalEventRegComponent: React.FC<ModalEventRegComponentProps> = ({open, event, account, handleClose}) => {

    let dateEvent =  event.event_start;

    return (

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                px: '1em',
                pt: '1em',
                pb: '1.4em',
                top: '50%',
                left: '50%',
                boxShadow: 24,
                border: 'none',
                outline: 'none',
                position: 'absolute',
                borderRadius: '0.25em',
                bgcolor: 'background.paper',
                transform: 'translate(-50%, -50%)',
                width: { xs: '80%', sm: '80%', md: '70%', lg: '50%', xl: '40%' },
            }}>
                <table style={{width: '100%'}}>
                    <tr>
                        <td colSpan={3} style={{
                            fontSize: '1.5em',
                            fontWeight: 'bold',
                            paddingBottom: '1em',
                            fontFamily: 'Open Sans',
                        }}>Bukti Registrasi Event</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Nama Event</td>
                        <td>:</td>
                        <td>{event.link_title}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Proyek</td>
                        <td>:</td>
                        <td>{event.proyek_name}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Mulai</td>
                        <td>:</td>
                        <td>{dateEvent}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        paddingBottom: '1em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Lokasi</td>
                        <td>:</td>
                        <td>{event .event_place}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Nama Member</td>
                        <td>:</td>
                        <td>{account!.fullname}</td>
                    </tr>
                    <tr style={{
                        fontSize: '0.9em',
                        paddingBottom: '1em',
                        fontFamily: 'Open Sans',
                    }}>
                        <td>Email Member</td>
                        <td>:</td>
                        <td>{account!.email}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{
                            fontSize: '0.9em',
                            fontFamily: 'Open Sans',
                            paddingTop: '1.75em',
                        }}>Terimakasih telah melakukan Registrasi Event</td>
                    </tr>
                    <br/>
                </table>
            </Box>
        </Modal>
    );
};