import React from 'react';

// Internal Import
import { AttendEventReq } from '../../../utils/structure/input';
import { SnackbarProps, StatusProps } from '../../../components/snackbar';
import { DefaultRes, EventDataMemberRes, EventMemberRes } from '../../../utils/structure/output';

// External Import
import axios, { AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

// MUI Import
import { LoadingButton } from '@mui/lab';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import { Box, Typography, Container, TextField, Button, CircularProgress, Modal } from '@mui/material';

const imgLogo = `/assets/logo_potrait_${process.env.REACT_APP_PROJECT_CODE}.png`;
export const AttendScreen: React.FC<{}> = (props) => {

    let navigate = useNavigate();
    const { id } = useParams();

    // Modal Status
    const [modalStatus, setModalStatus] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const onOpenModalStatus = (newStatus: StatusProps, newMessage: string) => {
        setTimeout(() => {
            setModalStatus({ open: true, status: newStatus, message: newMessage });
        }, 1000);
    };
    const onCloseModalStatus = () => {
        setModalStatus({ open: false, status: undefined, message: '' });
    }


    const [loading, setLoading] = React.useState<boolean>(true);
    const [loadingSubmit, setLoadingSubmit] = React.useState<boolean>(false);
    const [eventDetail, setEventDetail] = React.useState<EventDataMemberRes | undefined>(undefined);

    const [inputValue, setInputValue] = React.useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = () => {
        if (inputValue == "") {
            onOpenModalStatus('error', 'Harap masukkan email/nohp anda yang valid!');
            return;
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailRegex.test(inputValue) && !inputValue.startsWith("08")) {
            // Jika kedua validasi tidak sesuai
            onOpenModalStatus('error', 'Data tidak valid. Harus berupa email atau nomor HP yang benar.');
            return;
        }

        setLoadingSubmit(true); // Start loading
        setTimeout(() => {
            let data: AttendEventReq = {
                proyek: process.env.REACT_APP_PROJECT_ID_EREG!,
                event_id: eventDetail!.reff_id + "",
                id_member: inputValue + ""
            }
            postData(data);
        }, 2000);
    };

    // Hit/Consume RestAPI
    const getData = async () => {
        setLoading(true);
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_EVENT! + '?e_id=' + id;
            const response: AxiosResponse = await axios.get(url, {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            });

            let dtLoad: EventMemberRes = response.data;

            if (dtLoad.status === 200) {
                setEventDetail(dtLoad.data);
                setLoading(false);
            }
        } catch (error) {
        }
    }
    const postData = async (requestData: AttendEventReq) => {
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_ATTEND_EVENT!;
            const response: AxiosResponse = await axios.post(url, requestData, {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
            });

            setLoading(false);
            let eventResponse: DefaultRes = response.data;
            if (eventResponse.status == 200) {
                setLoadingSubmit(false);
                onOpenModalStatus('success', eventResponse.message);
                return;
            }
            setLoadingSubmit(false);
            onOpenModalStatus('error', eventResponse.message);
        } catch (error) {
            setLoadingSubmit(false);
            onOpenModalStatus('error', "Something problem, please refresh your broweser!");
        }
    }

    React.useEffect(() => { getData(); }, []);

    if (loading || eventDetail == undefined) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    bgcolor: '#007F61',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress size={18} sx={{ color: "#ffffff" }} />
                <Typography sx={{
                    ml: 1.5,
                    fontSize: 18,
                    fontWeight: 400,
                    color: '#FFFFFF',
                    fontFamily: 'Open Sans',
                }}>Please wait...</Typography>
            </Box>
        )
    }

    return (
        <Box sx={{
            px: '10%',
            width: '80%',
            height: '100vh',
            display: 'flex',
            bgcolor: '#F3F3F3',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
        }}>
            <Box
                component="img"
                src={process.env.PUBLIC_URL + imgLogo}
                sx={{ width: 224, objectFit: 'contain' }}
                alt={`Image Logo ${process.env.PUBLIC_URL + imgLogo}`}
            />

            <Typography sx={{
                mt: 1,
                fontSize: 24,
                color: '#000000',
                fontWeight: 200,
                textAlign: 'center',
                fontFamily: 'Open Sans',
            }}>
                {`"${eventDetail.title}"`}
            </Typography>

            {modalStatus.status == undefined && (
                <Container
                    maxWidth="sm"
                    sx={{
                        my: 8, py: 3, px: 4,
                        borderRadius: 4,
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'cetner',
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'cetner'
                    }}>
                        <Box sx={{
                            width: 32,
                            height: 32,
                            borderWidth: 1.5,
                            borderRadius: 1000,
                            borderStyle: 'solid',
                            borderColor: '#838383',

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <PermContactCalendarOutlinedIcon sx={{ fontSize: 20, color: "#838383" }} />
                        </Box>
                        <Typography sx={{
                            ml: 1.5,
                            fontSize: 24,
                            color: '#838383',
                            fontWeight: 400,
                            fontFamily: 'Open Sans',
                        }}>Form Kehadiran</Typography>
                    </Box>
                    <Typography sx={{
                        my: 2,
                        fontSize: 14,
                        fontWeight: 300,
                        color: '#000000',
                        textAlign: 'cemter',
                        fontFamily: 'Open Sans',
                    }}>Masukkan email/nohp anda untuk kehadiran</Typography>
                    <TextField
                        fullWidth
                        size='medium'
                        type='text'
                        margin="normal"
                        color='success'
                        variant="outlined"
                        value={inputValue}
                        sx={{ height: 42 }}
                        label="Email atau No HP"
                        onChange={handleInputChange}
                    />
                    <LoadingButton
                        fullWidth
                        color='success'
                        variant="contained"
                        onClick={handleSubmit}
                        loading={loadingSubmit}
                        sx={{ height: 52, mt: 5 }}
                    >Submit</LoadingButton>
                </Container>
            )}

            {modalStatus.status == 'success' && (
                <Container
                    maxWidth="sm"
                    sx={{
                        my: 8, py: 3, px: 4,
                        borderRadius: 4,
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'cetner',
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'cetner'
                    }}>
                        <Box sx={{
                            width: 32,
                            height: 32,
                            borderWidth: 1.5,
                            borderRadius: 1000,
                            borderStyle: 'solid',
                            borderColor: 'green',

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <DoneAllOutlinedIcon sx={{ fontSize: 20, color: "green" }} />
                        </Box>
                        <Typography sx={{
                            ml: 1.5,
                            fontSize: 24,
                            color: 'green',
                            fontWeight: 400,
                            fontFamily: 'Open Sans',
                        }}>Kehadiran Berhasil</Typography>
                    </Box>
                    <Typography sx={{
                        my: 2,
                        fontSize: 14,
                        fontWeight: 300,
                        color: '#000000',
                        textAlign: 'cemter',
                        fontFamily: 'Open Sans',
                    }}>{`Terimakasih untuk kehadirannya di event kami "${eventDetail.title}".`}</Typography>
                </Container>
            )}

            {modalStatus.status == 'error' && (
                <Container
                    maxWidth="sm"
                    sx={{
                        my: 8, py: 3, px: 4,
                        borderRadius: 4,
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'cetner',
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'cetner'
                    }}>
                        <Box sx={{
                            width: 32,
                            height: 32,
                            borderWidth: 1.5,
                            borderRadius: 1000,
                            borderStyle: 'solid',
                            borderColor: 'red',

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <CloseOutlinedIcon sx={{ fontSize: 20, color: "red" }} />
                        </Box>
                        <Typography sx={{
                            ml: 1.5,
                            fontSize: 24,
                            color: 'red',
                            fontWeight: 400,
                            fontFamily: 'Open Sans',
                        }}>Kehadiran Gagal</Typography>
                    </Box>
                    <Typography sx={{
                        my: 2,
                        fontSize: 14,
                        fontWeight: 300,
                        color: '#000000',
                        textAlign: 'cemter',
                        fontFamily: 'Open Sans',
                    }}>{modalStatus.message}</Typography>
                    <LoadingButton
                        fullWidth
                        color='error'
                        variant="contained"
                        loading={loadingSubmit}
                        onClick={onCloseModalStatus}
                        sx={{ height: 52, mt: 5 }}
                    >Kembali</LoadingButton>
                </Container>
            )}

            {modalStatus.status == 'warning' && (
                <Container
                    maxWidth="sm"
                    sx={{
                        my: 8, py: 3, px: 4,
                        borderRadius: 4,
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'cetner',
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'cetner'
                    }}>
                        <Box sx={{
                            width: 32,
                            height: 32,
                            borderWidth: 1.5,
                            borderRadius: 1000,
                            borderStyle: 'solid',
                            borderColor: 'yellow',

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <CloseOutlinedIcon sx={{ fontSize: 20, color: "yellow" }} />
                        </Box>
                        <Typography sx={{
                            ml: 1.5,
                            fontSize: 24,
                            color: 'yellow',
                            fontWeight: 400,
                            fontFamily: 'Open Sans',
                        }}>Kehadiran Warning</Typography>
                    </Box>
                    <Typography sx={{
                        my: 2,
                        fontSize: 14,
                        fontWeight: 300,
                        color: '#000000',
                        textAlign: 'cemter',
                        fontFamily: 'Open Sans',
                    }}>{modalStatus.message}</Typography>
                    <LoadingButton
                        fullWidth
                        color='warning'
                        variant="contained"
                        loading={loadingSubmit}
                        onClick={onCloseModalStatus}
                        sx={{ height: 52, mt: 2.5 }}
                    >Kembali</LoadingButton>
                </Container>
            )}
        </Box>
    )

    // return (
    //     <Box
    //         sx={{
    //             width: '100%',
    //             height: '100vh',
    //             display: 'flex',
    //             overflow: 'hidden',
    //             alignItems: 'center',
    //             position: 'relative',
    //             justifyContent: 'center',
    //         }}
    //     >
    //         {/* Background image */}
    //         <img
    //             src={eventDetail.thumb}
    //             alt="Background"
    //             style={{
    //                 zIndex: -1,
    //                 width: '100%',
    //                 height: '100%',
    //                 objectFit: 'cover',
    //                 position: 'absolute',
    //             }}
    //         />

    //         {/* Content container */}
    //         <Container
    //             maxWidth="sm"
    //             sx={{
    //                 mx: 4,
    //                 padding: '2rem',
    //                 textAlign: 'center',
    //                 borderRadius: '10px',
    //                 backgroundColor: 'rgba(255, 255, 255, 0.8)',
    //                 boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    //             }}
    //         >
    //             <Typography sx={{
    //                 mb: 1,
    //                 fontSize: 38,
    //                 color: '#000000',
    //                 fontWeight: 600,
    //                 fontFamily: 'Roboto',
    //             }}>
    //                 {eventDetail.title}
    //             </Typography>
    //             <Typography sx={{
    //                 fontSize: 20,
    //                 color: '#000000',
    //                 fontWeight: 300,
    //                 fontFamily: 'Roboto',
    //             }}>
    //                 Masukkan email/nohp anda untuk kehadiran
    //             </Typography>

    //             {/* Input Field */}
    //             <TextField
    //                 fullWidth
    //                 type='text'
    //                 margin="normal"
    //                 color='success'
    //                 variant="outlined"
    //                 value={inputValue}
    //                 sx={{ height: '56px' }}
    //                 label="Email atau No HP"
    //                 onChange={handleInputChange}
    //             />

    //             {/* Submit Button */}
    //             <LoadingButton
    //                 fullWidth
    //                 color='success'
    //                 variant="contained"
    //                 onClick={handleSubmit}
    //                 loading={loadingSubmit}
    //                 sx={{ height: '48px', mt: 2 }} // mt: 2 adds margin between the input and button
    //             >Submit</LoadingButton>
    //         </Container>

    //         <Modal
    //             open={modalStatus.open}
    //             onClose={onCloseModalStatus}
    //             aria-labelledby="modal-modal-title"
    //             aria-describedby="modal-modal-description"
    //         >
    //             <Box sx={{
    //                 py: 3,
    //                 px: 5,
    //                 top: '50%',
    //                 left: '50%',
    //                 width: { xs: "50%", sm: "50%", md: "50%", lg: "30%", xl: "30%" },
    //                 boxShadow: 24,
    //                 border: 'none',
    //                 outline: 'none',
    //                 position: 'absolute',
    //                 borderRadius: '0.5em',
    //                 backgroundColor: '#E4FBF5',
    //                 bgcolor: 'background.paper',
    //                 transform: 'translate(-50%, -50%)',
    //             }}>
    //                 <Typography sx={{
    //                     fontSize: 28,
    //                     fontWeight: '700',
    //                     textAlign: 'center',
    //                     fontFamily: 'Montserrat',
    //                     textTransform: 'capitalize',
    //                     color: modalStatus.status == 'error' ? 'red' : modalStatus.status == 'success' ? 'green' : 'yellow'
    //                 }}>{modalStatus.status}</Typography>
    //                 <Typography sx={{
    //                     fontSize: 16,
    //                     fontWeight: 400,
    //                     color: '#000000',
    //                     textAlign: 'center',
    //                     fontFamily: 'Montserrat',
    //                 }}>{modalStatus.message}</Typography>
    //                 <Button
    //                     fullWidth
    //                     variant="contained"
    //                     onClick={onCloseModalStatus}
    //                     sx={{
    //                         mt: 3,
    //                         fontSize: 12,
    //                         borderRadius: 4,
    //                         fontWeight: 600,
    //                         color: '#FFFFFF',
    //                         fontFamily: 'Montserrat',
    //                         textTransform: 'capitalize',
    //                         background: modalStatus.status == 'error' ? 'red' : modalStatus.status == 'success' ? 'green' : 'yellow',
    //                         '&:hover': { backgroundColor: modalStatus.status == 'error' ? 'red' : modalStatus.status == 'success' ? 'green' : 'yellow', color: "#ffffff" },
    //                     }}
    //                 >{"Close"}</Button>
    //             </Box>
    //         </Modal>
    //     </Box>
    // );
};