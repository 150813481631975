import React from 'react';

// Internal Import
import { Nl2br } from '../../../utils';
import { FooterComponent } from '../../../components/footer';
import { HelmetComponent } from '../../../components/helmet';
import { RegisterEventMemberReq } from '../../../utils/structure/input';
import { AppbarAccountComponent } from '../../../components/appbar/account';
import { ModalEventRegComponent } from '../../../components/modal/eventReg';
import { SnackbarComponent, SnackbarProps, StatusProps } from '../../../components/snackbar';
import { DefaultRes, DetailEventDataRes, DetailEventRes, LoginMemberDataRes } from '../../../utils/structure/output';

// External Import
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactSession } from 'react-client-session';

// MUI Import
import { Box, Stack, Skeleton, Typography, Button } from '@mui/material';


interface EventDetailScreenProps { }
export const EventDetailScreen: React.FC<EventDetailScreenProps> = (props) => {

    let navigate = useNavigate();
    let { e_id } = useParams();

    const [account, setAccount] = React.useState<undefined | LoginMemberDataRes>(undefined);
    React.useEffect(() => { }, []);

    const [loading, setLoading] = React.useState<boolean>(true);

    const [loadingReg, setLoadingReg] = React.useState<boolean>(false);
    const [modalEventReg, setModalEventReg] = React.useState<boolean>(false);
    const handelOpenModalEventReg = () => { setModalEventReg(true); }
    const handelCloseModalEventReg = () => { setModalEventReg(false);  }

    const [eventDetail, setEventDetail] = React.useState<undefined | DetailEventDataRes>(undefined);
    
    // Snackbar
    const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, status: undefined, message: '' });
    const handleOpenSnackbar = (newStatus: StatusProps, newMessage: string, time: number) => {
        setSnackbar({ open: true, status: newStatus, message: newMessage });
        setTimeout(() => { handleCloseSnackbar(); }, 5000);
    };
    const handleCloseSnackbar = () => { setSnackbar({ open: false, status: undefined, message: '' }); };
    
    // Hit/Consume RestAPI
    const getData = async (dtAccount: LoginMemberDataRes) => {
        try {
            let url: string = process.env.REACT_APP_API_BASE_URL!+process.env.REACT_APP_PATH_DETAIL_EVENT!+'?e_id='+e_id;
            const response: AxiosResponse = await axios.get(url, {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', Authorization: 'Bearer ' + dtAccount.token},
            });

            let dtLoad: DetailEventRes = response.data;
            if (dtLoad.status === 200) {
                setEventDetail(dtLoad.data);
            }
            setLoading(false);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                setAccount(undefined);
                ReactSession.set(process.env.REACT_APP_AUTH, undefined);
                handleOpenSnackbar('error', "Unauthorized request, please login again!", 3000);
                navigate('/login', { replace: true });
            } else {
                handleOpenSnackbar('error', "Something problem, please refresh your broweser!", 3000);
            }
        }
    }

    const handleReg = async() => {
        setLoadingReg(true);
        try {

            let requestData: RegisterEventMemberReq = {
                event_id: ""+eventDetail?.event_reff_id,
                id_member: "" +account?.mid
            }
            let url: string = process.env.REACT_APP_API_BASE_URL! + process.env.REACT_APP_PATH_REGISTER_EVENT!;
            const response: AxiosResponse = await axios.post(url, requestData, { headers: {'Accept': 'application/json', 'Content-Type': 'application/json', Authorization: 'Bearer ' + account!.token} });
            let dataResponse: DefaultRes = response.data;

            if (dataResponse.status == 200) {
                setLoadingReg(false);
                handleOpenSnackbar('success', dataResponse.message, 3000);
                setTimeout(() => { handelOpenModalEventReg(); }, 3000);
            } else {
                setLoadingReg(false);
                handleOpenSnackbar('error', dataResponse.message, 5000);
            }
        } catch (error) {
            setLoading(false);
            const axiosError = error as AxiosError;
            let errResponse: DefaultRes | any = axiosError.response?.data;
            handleOpenSnackbar('error', errResponse?.message, 5000);
        }
    }

    React.useEffect(() => {
        let dtAccount: LoginMemberDataRes = ReactSession.get(process.env.REACT_APP_AUTH)
        setAccount(dtAccount);
        getData(dtAccount);
    }, []);

    return (
        <Stack sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: { xs: '3.4em', sm: '3.6em', md: '3.8em', lg: '4em', xl: '4.2em' },
        }}>
            <HelmetComponent />
            <SnackbarComponent snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />

            <AppbarAccountComponent page={'home'} account={account} setAccount={setAccount} />

            <Box sx={{
                pl: '5%',
                pr: '5%',
                width: '90%',
                backgroundColor: '#ffffff',
                py: { xs: '1em', sm: '1.5em', md: '1.75em', lg: '2.5em', xl: '2.5em' },
            }}>
                <Typography sx={{
                    mb: '1.5em',
                    color: '#000000',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                    fontSize: { xs: '0.8em', sm: '0.9em', md: '1em', lg: '1.25em', xl: '1.25em' },
                }}>{'Events'}</Typography>
                {!loading && eventDetail == null && (
                    <Typography sx={{
                        mb: '1.5em',
                        color: '#000000',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        fontSize: { xs: '0.55em', sm: '0.65em', md: '0.75em', lg: '1em', xl: '1em' },
                    }}>{'Tidak ada event yang tersedia.'}</Typography>
                )}
                {loading&&(
                    <Skeleton variant='rectangular' sx={{ width: '100%', height: '7em', borderRadius: '1em'}} />
                )}

                {eventDetail != undefined && (
                    <Box
                        sx={{
                            my: '1.5em',
                            display: 'flex',
                            cursor: 'pointer',
                            borderRadius: '0.5em',
                            flexDirection: 'column',
                            backgroundColor: '#ffffff',
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
                            px: { xs: '1em', sm: '1.25', md: '1.5m', lg: '2em', xl: '2em' },
                            py: { xs: '0.5em', sm: '0.6', md: '0.75em', lg: '1em', xl: '1em' },
                        }}
                    >
                        {eventDetail.thumb != "" && (
                            <Box
                                component='img'
                                src={eventDetail.thumb}
                                sx={{ width: '100%', height: 'auto' }}
                            />
                        )}
                        <br />
                        <br />
                        <Typography sx={{
                            fontWeight: 500,
                            color: '#000000',
                            fontFamily: 'Roboto',
                            fontSize: { xs: '0.55em', sm: '0.65em', md: '0.75em', lg: '1em', xl: '1em' },
                        }}>{eventDetail.proyek_name}</Typography>
                        <Typography sx={{
                            fontWeight: 700,
                            color: '#000000',
                            fontFamily: 'Roboto',
                            fontSize: { xs: '0.8em', sm: '0.9em', md: '1em', lg: '1.25em', xl: '1.25em' },
                        }}>{eventDetail!.link_title}</Typography>
                        <Typography sx={{
                            color: '#000000',
                            fontFamily: 'Roboto',
                            fontSize: { xs: '0.45em', sm: '0.55em', md: '0.65em', lg: '0.9em', xl: '0.9em' },
                        }}>{eventDetail!.event_place}</Typography>
                        <br />
                        <div style={{width: '100%', height: '0.1em', backgroundColor: '#000'}}/>
                        <br />
                        <Typography sx={{
                            fontWeight: 700,
                            color: '#000000',
                            fontFamily: 'Roboto',
                            fontSize: { xs: '0.8em', sm: '0.9em', md: '1em', lg: '1.25em', xl: '1.25em' },
                        }}>Deskripsi Event</Typography>
                        <Typography sx={{
                            color: '#000000',
                            fontFamily: 'Roboto',
                            fontSize: { xs: '0.55em', sm: '0.65em', md: '0.75em', lg: '1em', xl: '1em' },
                        }}>{Nl2br(eventDetail.event_description)}</Typography>
                        <br />
                        <br />
                        <Button
                            fullWidth
                            size='large'
                            type='button'
                            color='success'
                            variant="contained"
                            onClick={handleReg}
                            disabled={loadingReg}
                            sx={{ textTransform: 'capitalize' }}
                        >{loadingReg?"Loading...":"Daftar Event"}</Button>
                    </Box>
                )}
            </Box>

            <FooterComponent />

            {eventDetail != undefined && (
                <ModalEventRegComponent 
                    open={modalEventReg}
                    handleClose={handelCloseModalEventReg}
                    event={eventDetail}
                    account={account}
                />
            )}
        </Stack>
    );
};