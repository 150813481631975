import React from 'react';

// External Import
import { useNavigate } from 'react-router-dom';
import AppsIcon from '@mui/icons-material/Apps';

// MUI Import
import { Box, Typography } from '@mui/material';


export interface FooterComponentProps { }
export const FooterComponent: React.FC<FooterComponentProps> = (props) => {
    let navigate = useNavigate();

    const handleBackHome = () => {
        navigate('/', { replace: true })
    }
    return (
        <Box sx={{
            mt: '10em',
            left: 0,
            bottom: 0,
            zIndex: 100,
            width: '100%',
            display: 'flex',
            cursor: 'pointer',
            position: 'fixed',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#1B2B66',
            height: { xs: '3.4em', sm: '3.6em', md: '3.8em', lg: '4em', xl: '4.2em' },
        }}>
            <Box onClick={handleBackHome}>
                <AppsIcon sx={{ color: '#ffffff' }} />
                <Typography sx={{
                    color: '#ffffff',
                    fontSize: '0.6em',
                    fontWeight: '400',
                    fontFamily: 'Roboto'
                }}>Home</Typography>
            </Box>
        </Box>
    )
}